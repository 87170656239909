import React from "react"
import { Link } from 'gatsby';
import User from '../images/user.svg'

const doctorPreview = ({ doctor }) => {
    return (
        <div className="my-4 md:w-1/4 pr-4">
            <div className="rounded-lg">
                <div className="mr-4">
                    { doctor.avatar && (
                        <img className="rounded-lg flex-shrink-0 flex-grow" srcSet={doctor.avatar.fluid.srcSet} alt={doctor.avatar.title} />
                    )}
                    { ! doctor.avatar && (
                        <img className="rounded-lg flex-shrink-0 flex-grow" src={User} alt={doctor.name} />
                    )}
                </div>
                <div className="pt-3 pr-3">
                    <div className="flex">
                        { doctor.specializations && doctor.specializations.map(({slug, name}) => {
                            return (
                                <Link className="mr-2 uppercase tracking-wide bg-orange-100 text-main p-1 rounded-lg text-xs font-semibold" to={`/lekarze/${slug}`}>{name}</Link>
                            )
                        }) }
                    </div>
                    <Link className="text-lg font-semibold">{doctor.name}</Link>
                    <h3 className="text-gray-700">{ doctor.profession }</h3>
                </div>
            </div>
        </div>
    )
};

export default doctorPreview;