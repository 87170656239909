import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout";
import SEO from "../components/seo";
import DoctorPreview from "../components/doctorPreview";

class Specialization extends React.Component {
    render() {
        const { data } = this.props;
        const siteTitle = data.site.siteMetadata.title;
        const specialization = data.contentfulSpecialization;
        const doctors = specialization.doctor;

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO title={specialization.name + " Sandomierz"} />
                <div className="mx-auto container">
                    <div className="py-10">
                        <div className="sm:flex sm:items-center">
                            <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                                <svg className="fill-current text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path d="M315.793 19.932h-59.796v37.372h41.109v70.384c0 61.82-50.297 112.117-112.117 112.117S72.873 189.509 72.873 127.689V57.304h39.864V19.932h-58.55c-10.321 0-18.686 8.365-18.686 18.686v89.071c0 82.431 67.058 149.489 149.489 149.489s149.489-67.058 149.489-149.489V38.618c0-10.321-8.365-18.686-18.686-18.686z"/>
                                    <path d="M388.668 292.749v88.448c0 51.518-41.913 93.431-93.431 93.431s-93.431-41.913-93.431-93.431V259.114h-37.372v122.083c0 72.122 58.674 130.803 130.803 130.803 72.122 0 130.803-58.681 130.803-130.803v-88.448h-37.372z"/>
                                    <path d="M407.361 171.912c-38.132 0-69.145 31.013-69.145 69.139 0 38.12 31.013 69.139 69.139 69.139 38.126 0 69.139-31.019 69.145-69.139-.001-38.126-31.019-69.139-69.139-69.139zm-.006 100.906c-17.521 0-31.773-14.251-31.773-31.766s14.251-31.766 31.773-31.766c17.515 0 31.766 14.251 31.766 31.766s-14.251 31.766-31.766 31.766zM112.114 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686S130.8 68.871 130.8 58.55V18.686C130.8 8.365 122.435 0 112.114 0zM256.62 0c-10.321 0-18.686 8.365-18.686 18.686V58.55c0 10.321 8.365 18.686 18.686 18.686s18.686-8.365 18.686-18.686V18.686C275.306 8.365 266.941 0 256.62 0z"/>
                                </svg>
                            </div>
                            <h1 className="text-4xl leading-none font-bold text-gray-800 mt-2 sm:mt-0 sm:ml-4">{ specialization.name }</h1>
                        </div>
                        <p className="text-gray-700 max-w-2xl mt-3 markdown" dangerouslySetInnerHTML={{ __html: specialization.description.childContentfulRichText.html }} />
                    </div>

                    { doctors && (
                      <>
                      <span className="text-main text-xs leading-none tracking-wide uppercase font-bold">Nasi specjaliści</span>
                        <div className="flex flex-wrap mb-8">
                            {doctors.map((doctor, index) => {
                                return (
                                <DoctorPreview doctor={doctor} key={index + '-s'} />
                                )
                            })}
                        </div>
                      </>

                        )}


                    { specialization.offer && (
                      <>
                          <div className="py-10">
                              <div className="sm:flex sm:items-center">
                                  <div className="inline-flex w-12 h-12 items-center justify-around bg-main rounded-lg">
                                      <svg className="fill-current text-white w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                          <path d="M352.459 220c0-11.046-8.954-20-20-20h-206c-11.046 0-20 8.954-20 20s8.954 20 20 20h206c11.046 0 20-8.954 20-20zM126.459 280c-11.046 0-20 8.954-20 20s8.954 20 20 20H251.57c11.046 0 20-8.954 20-20s-8.954-20-20-20H126.459z"/>
                                          <path d="M173.459 472H106.57c-22.056 0-40-17.944-40-40V80c0-22.056 17.944-40 40-40h245.889c22.056 0 40 17.944 40 40v123c0 11.046 8.954 20 20 20s20-8.954 20-20V80c0-44.112-35.888-80-80-80H106.57c-44.112 0-80 35.888-80 80v352c0 44.112 35.888 80 80 80h66.889c11.046 0 20-8.954 20-20s-8.954-20-20-20z"/>
                                          <path d="M467.884 289.572c-23.394-23.394-61.458-23.395-84.837-.016l-109.803 109.56c-2.332 2.327-4.052 5.193-5.01 8.345l-23.913 78.725c-2.12 6.98-.273 14.559 4.821 19.78 3.816 3.911 9 6.034 14.317 6.034 1.779 0 3.575-.238 5.338-.727l80.725-22.361c3.322-.92 6.35-2.683 8.79-5.119l109.573-109.367c23.394-23.394 23.394-61.458-.001-84.854zM333.776 451.768l-40.612 11.25 11.885-39.129 74.089-73.925 28.29 28.29-73.652 73.514zM439.615 346.13l-3.875 3.867-28.285-28.285 3.862-3.854c7.798-7.798 20.486-7.798 28.284 0 7.798 7.798 7.798 20.486.014 28.272zM332.459 120h-206c-11.046 0-20 8.954-20 20s8.954 20 20 20h206c11.046 0 20-8.954 20-20s-8.954-20-20-20z"/>
                                      </svg>
                                  </div>
                                  <h1 className="text-4xl leading-none font-bold text-gray-800 mt-2 sm:mt-0 sm:ml-4">Nasza oferta</h1>
                              </div>
                          </div>
                          <div className="markdown"  dangerouslySetInnerHTML={{ __html: specialization.offer.childContentfulRichText.html }} />
                      </>
                    )}
                </div>
            </Layout>
        )
    }
}

export default Specialization;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulSpecialization( slug: { eq: $slug }) {
        slug
        name
        doctor {
          avatar {
            fluid {
              srcSet
            }
          }
          specializations {
            slug
            name
          }
          profession
          name
        }
        description {
          childContentfulRichText {
            html
          }
        }
        offer {
            childContentfulRichText {
                html
            }
        }
    }
  }
`;
